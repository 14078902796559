<template>
  <asterix-form-modal
    :title="title"
    closable
    modal-class="sm:max-w-3xl"
    @submit="onUserSubmit"
    @cancel="$emit('cancel')"
  >
    <template #content>
      <div class="p-6 overflow-auto content-height">
        <div v-if="showForm">
          <basic-info :mode="mode" :entity="entity" :item="userItem">
            <asterix-new-password-input v-if="isNewForm" v-model="password" required="required" validate-on-blur />
            <form-row v-if="showToggleEnable">
              <template #left>
                <sun-label-group :text="enabledTitle">
                  <sun-toggle id="toggle-enable-user" v-model="userItem.enabled" name="isEnabled" />
                </sun-label-group>
              </template>
            </form-row>
          </basic-info>
          <card-form class="mt-12">
            <template slot="title"> Context Roles </template>
            <template slot="form">
              <context-roles-selector :id="`${mode}-user`" v-model="userItem.contextRoles" :user="userItem" />
            </template>
          </card-form>
        </div>
        <div v-else>
          <card-form-loading></card-form-loading>
          <card-form-loading :rows="2" class="mt-10"></card-form-loading>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="justify-between sm:flex sm:flex-row">
        <div class="flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto">
          <save-button
            :id="`${mode}-user-submit`"
            button-class="w-full"
            variant="pill"
            type="submit"
            class="w-full"
            :disabled="loading"
          />
        </div>
        <div class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <sun-button variant="pill" class="w-full text-sm custom-p-1" color="white" @click="$emit('cancel')">
            Cancel
          </sun-button>
        </div>
      </div>
    </template>
  </asterix-form-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { USER } from '@/store/modules/auth/keys';
import { SET_LOAD } from '@/store/modules/load/keys';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';
import { CONTEXTS } from '@/model/shared/contexts';
import { ERRORS } from '@/i18n/forms/errors';
import { createUser, getUserById, updateUser } from '@/services/modules/Core/user';
import BasicInfo from '@/components/organisms/shared/formFields/BasicInfo';
import ContextRolesSelector from '@/components/organisms/shared/contextRoles/ContextRolesSelector';
import SaveButton from '@/components/atoms/SaveButton';
import CardForm from '@/components/atoms/CardForm';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import FormRow from '@/components/atoms/FormRow/FormRow.vue';
import CONFIG from './config';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal';
import UserCore from '@/entities/Auth/UserCore';
import { ROLES } from '@/model/shared/roles';
import AsterixNewPasswordInput from '@/components/atoms/AsterixNewPasswordInput';

export default {
  name: 'UserModal',
  components: {
    AsterixNewPasswordInput,
    AsterixFormModal,
    SaveButton,
    BasicInfo,
    CardForm,
    CardFormLoading,
    ContextRolesSelector,
    FormRow,
  },
  data: () => ({
    loading: false,
    formErrors: CONFIG.errors,
    i18nErrors: ERRORS.en,
    userItem: new UserCore(null, '', '', '', [
      { context: CONTEXTS.CORE.id, role: ROLES.USER.id, ids: [], settings: {} },
    ]),
    passwordMinlength: 6,
    connectionFailedToServer: false,
    entity: 'user',
    password: '',
  }),
  computed: {
    ...mapGetters({
      activeContext: ACTIVE_CONTEXT,
      user: USER,
    }),
    userId() {
      return this.$route.params.id || null;
    },
    mode() {
      return this.userId ? 'edit' : 'new';
    },
    title() {
      return `${this.userId ? 'Edit' : 'New'} User`;
    },
    isNewForm() {
      return this.mode === 'new';
    },
    routeToGo() {
      if (!this.activeContext) {
        return `${CONTEXT_BOARD_BASE}-users`;
      }
      return `${this.activeContext.baseUrl}-users`;
    },
    showForm() {
      return this.mode === 'new' || (this.mode === 'edit' && !!this.userItem.name);
    },
    isCheckedEnableUser() {
      return this.userItem.enabled;
    },
    enabledTitle() {
      return this.userItem.enabled ? 'Enabled' : 'Disabled';
    },
    showToggleEnable() {
      const isOwnUser = this.user.id === this.userItem.id;
      return this.isNewForm || (this.userItem?.id && !isOwnUser);
    },
  },
  async mounted() {
    if (this.mode === 'new') {
      this.setLoad(false);
    } else {
      await this.setUserData();
    }
  },
  methods: {
    ...mapActions({
      setLoad: SET_LOAD,
      createToast: CREATE_TOAST,
    }),
    async setUserData() {
      this.setLoad();
      await this.loadUser();
    },
    async loadUser() {
      try {
        const { data } = await getUserById(this.userId);
        this.userItem = data;
      } catch (error) {
        this.createToast(Toast.error(`We can't get user`, error.message));
        this.connectionFailedToServer = true;
      } finally {
        this.setLoad(false);
      }
    },

    async onUserSubmit({ valid, form }) {
      if (!valid) return;
      await this.save(form);
    },
    async save(form) {
      const createdOrUpdated = this.mode === 'new' ? 'created' : 'updated';
      try {
        this.loading = true;
        this.setLoad();
        const userFormObject = this.setUserObject(form);

        if (this.mode === 'new') {
          await createUser(userFormObject);
        } else {
          await updateUser(userFormObject);
        }

        this.createToast(
          Toast.success(`User ${createdOrUpdated}`, `User ${userFormObject.name} was ${createdOrUpdated} successfully.`)
        );
        this.$emit('save');
      } catch (error) {
        this.createToast(Toast.error(`User not ${createdOrUpdated}`, error.message));
      } finally {
        this.loading = false;
        this.setLoad(false);
      }
    },
    setUserObject(form) {
      const nameValue = form.name.value;
      const emailValue = form.email.value;
      const passwordValue = this.password || '';
      const contextRolesValue = this.userItem.contextRoles;
      const id = this.userId;
      const enabled = this.userItem.enabled;

      return new UserCore(id, nameValue, emailValue, passwordValue, contextRolesValue, enabled);
    },
  },
};
</script>

<style scoped>
.content-height {
  height: 66vh;
}
</style>
