var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-form-modal", {
    attrs: { title: _vm.title, closable: "", "modal-class": "sm:max-w-3xl" },
    on: {
      submit: _vm.onUserSubmit,
      cancel: function ($event) {
        return _vm.$emit("cancel")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "p-6 overflow-auto content-height" }, [
              _vm.showForm
                ? _c(
                    "div",
                    [
                      _c(
                        "basic-info",
                        {
                          attrs: {
                            mode: _vm.mode,
                            entity: _vm.entity,
                            item: _vm.userItem,
                          },
                        },
                        [
                          _vm.isNewForm
                            ? _c("asterix-new-password-input", {
                                attrs: {
                                  required: "required",
                                  "validate-on-blur": "",
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              })
                            : _vm._e(),
                          _vm.showToggleEnable
                            ? _c("form-row", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "left",
                                      fn: function () {
                                        return [
                                          _c(
                                            "sun-label-group",
                                            {
                                              attrs: { text: _vm.enabledTitle },
                                            },
                                            [
                                              _c("sun-toggle", {
                                                attrs: {
                                                  id: "toggle-enable-user",
                                                  name: "isEnabled",
                                                },
                                                model: {
                                                  value: _vm.userItem.enabled,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.userItem,
                                                      "enabled",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "userItem.enabled",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1804860945
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "card-form",
                        { staticClass: "mt-12" },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(" Context Roles "),
                          ]),
                          _c(
                            "template",
                            { slot: "form" },
                            [
                              _c("context-roles-selector", {
                                attrs: {
                                  id: `${_vm.mode}-user`,
                                  user: _vm.userItem,
                                },
                                model: {
                                  value: _vm.userItem.contextRoles,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.userItem, "contextRoles", $$v)
                                  },
                                  expression: "userItem.contextRoles",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("card-form-loading"),
                      _c("card-form-loading", {
                        staticClass: "mt-10",
                        attrs: { rows: 2 },
                      }),
                    ],
                    1
                  ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("div", { staticClass: "justify-between sm:flex sm:flex-row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto",
                },
                [
                  _c("save-button", {
                    staticClass: "w-full",
                    attrs: {
                      id: `${_vm.mode}-user-submit`,
                      "button-class": "w-full",
                      variant: "pill",
                      type: "submit",
                      disabled: _vm.loading,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto",
                },
                [
                  _c(
                    "sun-button",
                    {
                      staticClass: "w-full text-sm custom-p-1",
                      attrs: { variant: "pill", color: "white" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }